/* prettier-ignore */
@import url(
  https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic);

/* UNIVERSAL */

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
}

/* ROOT FONT STYLES */

* {
  font-family: "Lato", Helvetica, sans-serif;
  color: #333447;
  line-height: 1.5;
}

/* TYPOGRAPHY */

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.8;
}

.font-light {
  font-weight: 200;
}

.font-regular {
  font-weight: 400;
}

.font-heavy {
  font-weight: 700;
}

/* POSITIONING */

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center,
.center-sm {
  text-align: center;
  margin: 0 auto;
}

.justify {
  text-align: justify;
}

/* ==== GRID SYSTEM ==== */

.container {
  width: 90%;
  margin: 0 auto;
}

.row {
  position: relative;
  width: 100%;
}

.row [class^="col"] {
  float: left;
  margin: 8px 2%;
  min-height: 2px;
}

[class^="n-col"] {
  float: left;
  min-height: 2px;
}

[class^="n-col-1"]:first-of-type {
  margin-left: 0;
}

[class^="n-col-5"]:last-of-type {
  margin-right: 0;
}

.n-col-1,
.n-col-2,
.n-col-3,
.n-col-4,
.n-col-5,
.n-col-6 {
  width: 100%;
  margin: 8px 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: 96%;
}

.col-1-sm {
  width: 4.33%;
}

.col-2-sm {
  width: 12.66%;
}

.col-3-sm {
  width: 21%;
}

.col-4-sm {
  width: 29.33%;
}

.col-5-sm {
  width: 37.66%;
}

.col-6-sm {
  width: 46%;
}

.col-7-sm {
  width: 54.33%;
}

.col-8-sm {
  width: 62.66%;
}

.col-9-sm {
  width: 71%;
}

.col-10-sm {
  width: 79.33%;
}

.col-11-sm {
  width: 87.66%;
}

.col-12-sm {
  width: 96%;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

.hidden-sm {
  display: none;
}

@media only screen and (min-width: 540px) {
  .container {
    width: 80%;
  }
}

@media only screen and (min-width: 720px) {
  .col-1 {
    width: 4.33%;
  }

  .col-2 {
    width: 12.66%;
  }

  .col-3 {
    width: 21%;
  }

  .col-4 {
    width: 29.33%;
  }

  .col-5 {
    width: 37.66%;
  }

  .col-6 {
    width: 46%;
  }

  .col-7 {
    width: 54.33%;
  }

  .col-8 {
    width: 62.66%;
  }

  .col-9 {
    width: 71%;
  }

  .col-10 {
    width: 79.33%;
  }

  .col-11 {
    width: 87.66%;
  }

  .col-12 {
    width: 96%;
  }

  /* NESTED GRID */

  [class^="n-col"] {
    margin: 8px 4.3478%;
  }

  .n-col-1 {
    width: 9.4202%;
  }

  .n-col-2 {
    width: 27.536%;
  }

  .n-col-3 {
    width: 45.6519%;
  }

  .n-col-4 {
    width: 63.7677%;
  }

  .n-col-5 {
    width: 81.8836%;
  }

  .n-col-6 {
    width: 99.9999%;
  }

  /* END NESTED GRID */

  .hidden-sm {
    display: block;
  }

  .center-sm {
    margin: 0 2% 0 2%;
    text-align: left;
  }
}

@media only screen and (min-width: 960px) {
  .container {
    width: 75%;
    max-width: 960px;
  }
}
